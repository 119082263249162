<script setup>
import {computed} from 'vue';
import {Link as InertiaLink, usePage} from '@inertiajs/vue3';

const props = defineProps({
    breadcrumbs: {
        type: Array,
        default: null,
    },
});

const breadcrumbs = computed(() => props.breadcrumbs || usePage().props?.breadcrumbs || []);
</script>

<template>
    <nav v-if="breadcrumbs.length" class="breadcrumbs">
        <ul>
            <li v-for="(path, index) in breadcrumbs" :key="index">
                <InertiaLink v-if="path.url && !path.current" :href="path.url">
                    {{ path.title }}
                </InertiaLink>
                <span v-else class="flex items-center">
                    {{ path.title }}
                </span>
            </li>
        </ul>
    </nav>
</template>
