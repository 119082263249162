<template>
    <div class="flex border border-gray-300 p-2 space-x-2">
        <span class="text-gray-500 uppercase">{{ $t('frontend.sharing.share_on') }}</span>
        <ShareNetwork
            ref="shareNetworkRef_facebook"
            network="facebook"
            :url="$page.props.url"
            :title="title"
            :description="description"
            :hashtags="$page.props.appName.toLowerCase()"
            @open="runWorkaround(ref(shareNetworkRef_facebook))">
            <img :src="facebookIconUrl" class="w-6" alt="Facebook" />
        </ShareNetwork>
        <ShareNetwork
            ref="shareNetworkRef_twitter"
            network="twitter"
            :url="$page.props.url"
            :title="title"
            :hashtags="$page.props.appName.toLowerCase()"
            @open="runWorkaround(ref(shareNetworkRef_twitter))">
            <img :src="xTwitterIconUrl" class="w-6" alt="X (Twitter)" />
        </ShareNetwork>
        <ShareNetwork
            ref="shareNetworkRef_linkedin"
            network="linkedin"
            :url="$page.props.url"
            :title="title"
            @open="runWorkaround(ref(shareNetworkRef_linkedin))">
            <img :src="linkedInIconUrl" class="w-6" alt="LinkedIn" />
        </ShareNetwork>
        <ShareNetwork
            ref="shareNetworkRef_whatsapp"
            network="whatsapp"
            :url="$page.props.url"
            :title="title"
            :description="description"
            @open="runWorkaround(ref(shareNetworkRef_whatsapp))">
            <img :src="whatsAppIconUrl" class="w-6" alt="WhatsApp" />
        </ShareNetwork>
        <ShareNetwork
            ref="shareNetworkRef_email"
            network="email"
            :url="$page.props.url"
            :title="title"
            :description="description"
            @open="runWorkaround(ref(shareNetworkRef_email))">
            <img :src="emailIconUrl" class="w-6" alt="Email" />
        </ShareNetwork>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import {
    emailIconUrl,
    facebookIconUrl,
    linkedInIconUrl,
    whatsAppIconUrl,
    xTwitterIconUrl,
} from '@/images/components/social_media';

defineProps({
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        default: '',
    },
});

const shareNetworkRef_facebook = ref(null);
const shareNetworkRef_twitter = ref(null);
const shareNetworkRef_linkedin = ref(null);
const shareNetworkRef_whatsapp = ref(null);
const shareNetworkRef_email = ref(null);

// @see https://github.com/nicolasbeauvais/vue-social-sharing/issues/354#issuecomment-1991853426
const runWorkaround = (component) => {
    if (component.value === null) return;

    clearInterval(component.value?.popupInterval);
    component.value.popupWindow = undefined;
};
</script>
