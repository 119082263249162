<template>
    <ul class="flex w-full flex-col items-start justify-start px-6 py-4 text-left md:px-14 2xl:px-16">
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-j-deep-blue md:cursor-pointer xl:block xl:hidden border-j-grey-2">
            <InertiaLink :href="route('about')" title="">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-j-deep-blue sm:leading-9 md:text-3xl md:leading-[50px] text-white">
                    {{ $t('frontend.about_us', {app_name: $page.props.appName.toString()}) }}
                </div>
            </InertiaLink>
        </li>
        <!-- Products menu -->
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-secondary md:cursor-pointer xl:block xl:hidden border-gray-100">
            <div class="group relative font-medium md:cursor-pointer">
                <button
                    type="button"
                    aria-haspopup="menu"
                    aria-expanded="false"
                    data-headlessui-state=""
                    class="flex w-full items-center justify-between py-5 text-2xl font-medium leading-9 duration-300 group-hover:text-j-deep-blue md:cursor-pointer md:text-3xl md:leading-[50px] text-secondary"
                    @click="toggleProductsMenu">
                    {{ $t('frontend.products') }}
                    <ArrowRight class="h-auto w-3 duration-300 group-hover:text-secondary rotate-90 text-primary" />
                </button>
                <!-- Products dropdown mobile menu -->
                <ul v-if="productsMenuOpened" role="menu" tabindex="0" class="">
                    <li
                        class="bg-j-dark-background duration-200 hover:bg-j-dark-background-3 border-b border-j-grey-2"
                        role="menuitem"
                        tabindex="-1">
                        <InertiaLink
                            :href="route('categories.index')"
                            class="flex items-center justify-between border-b py-5 pl-4 pr-2 last:border-0"
                            title="">
                            <div class="flex items-center gap-4">
                                <p class="text-sm font-medium uppercase tracking-widest">
                                    {{ $t('frontend.all_categories') }}
                                </p>
                            </div>
                            <ArrowRight class="h-3 w-3 text-secondary opacity-0 duration-200 group-hover:opacity-100" />
                        </InertiaLink>
                    </li>
                    <li v-for="(name, slug) in $page.props.parentCategories" :key="slug">
                        <InertiaLink
                            :href="route('categories.show', {categories: slug})"
                            class="flex items-center justify-between border-b py-5 pl-4 pr-2 last:border-0"
                            title="">
                            <div class="flex items-center gap-4">
                                <p class="text-sm font-medium uppercase tracking-widest">{{ name }}</p>
                            </div>
                            <ArrowRight class="h-3 w-3 text-secondary opacity-0 duration-200 group-hover:opacity-100" />
                        </InertiaLink>
                    </li>
                </ul>
            </div>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-j-deep-blue md:cursor-pointer xl:block xl:hidden border-j-grey-2">
            <InertiaLink :href="route('why')" title="">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-j-deep-blue sm:leading-9 md:text-3xl md:leading-[50px] text-white">
                    {{ $t('frontend.why', {app_name: $page.props.appName.toString()}) }}
                </div>
            </InertiaLink>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-j-deep-blue md:cursor-pointer xl:block xl:hidden border-j-grey-2">
            <InertiaLink :href="route('technology')" title="">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-j-deep-blue sm:leading-9 md:text-3xl md:leading-[50px] text-white">
                    {{ $t('frontend.mcm_technology', {app_name: $page.props.appName.toString()}) }}
                </div>
            </InertiaLink>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-j-deep-blue md:cursor-pointer xl:block xl:hidden border-j-grey-2">
            <InertiaLink :href="route('treatments')" title="">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-j-deep-blue sm:leading-9 md:text-3xl md:leading-[50px] text-white">
                    {{ $t('frontend.treatments') }}
                </div>
            </InertiaLink>
        </li>
        <li
            class="group w-full border-b pr-1 text-white duration-200 hover:border-j-deep-blue md:cursor-pointer xl:block xl:hidden border-j-grey-2">
            <InertiaLink :href="route('events')" title="">
                <div
                    class="flex justify-between py-5 text-2xl font-medium duration-200 group-hover:text-j-deep-blue sm:leading-9 md:text-3xl md:leading-[50px] text-white">
                    {{ $t('frontend.events') }}
                </div>
            </InertiaLink>
        </li>
        <li class="mt-10 w-full xl:hidden">
            <a
                :href="route('contact.index')"
                class="group relative inline-block px-[30px] text-center text-sm font-medium uppercase tracking-widest text-secondary-content duration-300 w-full md:cursor-pointer"
                disabled="false">
                <Angle class="absolute left-0.5 top-0 h-full w-[30px] text-secondary" />
                <div
                    class="flex h-full flex-col justify-center bg-secondary px-6 py-3.5 duration-150 group-hover:shadow-[0px_25px_46px_rgba(19,43,255,0.2)]">
                    <p class="text-lg leading-9 tracking-normal md:text-3xl">{{ $t('frontend.contact.title') }}</p>
                </div>
                <Angle class="absolute right-0.5 top-0 h-full w-[30px] -scale-x-100 text-secondary" />
            </a>
        </li>
    </ul>
</template>
<script setup>
import {ref} from 'vue';
import {Link as InertiaLink} from '@inertiajs/vue3';
import Angle from '@/images/frontend/angle.svg';
import ArrowRight from '@/images/frontend/arrow-right.svg';

const productsMenuOpened = ref(false);

const toggleProductsMenu = () => (productsMenuOpened.value = !productsMenuOpened.value);
</script>
