<template>
    <footer class="relative bg-black backdrop-opacity-0">
        <div class="mx-auto max-w-7xl my-6 px-6 lg:px-12 2lg:px-12 2xl:px-0">
            <!-- Vertical layout -->
            <div class="flex flex-col space-y-8 md:space-y-12">
                <!-- Logo + Addresses row -->
                <div class="flex flex-col md:flex-row space-x-0 md:space-x-12 space-y-4 md:space-y-0">
                    <!-- Logo -->
                    <div class="md:basis-1/4 self-center md:self-start">
                        <a
                            :href="route('home')"
                            class="text-2xl font-light tracking-widest text-white"
                            :aria-label="$page.props.appName">
                            <LogoVertical class="h-28 w-auto brightness-200" :alt="$page.props.appName" />
                        </a>
                    </div>
                    <!-- Legal Address -->
                    <div v-if="hasCompanyInfo" class="md:basis-1/4 text-white">
                        <h2 class="text-secondary text-xl">{{ $t('frontend.footer.legal_office') }}</h2>
                        <p>{{ $page.props.legalInfo.legal_name }}</p>
                        <p>{{ $page.props.legalInfo.legal_address }}</p>
                        <p>
                            {{ $page.props.legalInfo.legal_zip }} {{ $page.props.legalInfo.legal_city }},
                            {{ $page.props.legalInfo.legal_country }}
                        </p>
                    </div>
                    <!-- Export Address -->
                    <div v-if="hasCompanyInfo" class="md:basis-1/4 text-white">
                        <h2 class="text-secondary text-xl">{{ $t('frontend.footer.export_office') }}</h2>
                        <p>{{ $t('frontend.footer.milan_office') }}: {{ $page.props.legalInfo.legal_phone }}</p>
                        <p>
                            <a :href="`mailto:${$page.props.legalInfo.legal_email}`">{{
                                $page.props.legalInfo.legal_email
                            }}</a>
                        </p>
                    </div>
                    <!-- Social Info -->
                    <div v-if="hasSocialInfo" class="md:basis-1/4 flex flex-row space-x-2 items-center md:justify-end">
                        <div v-if="$page.props.socialInfo.social_facebook">
                            <a
                                :href="$page.props.socialInfo.social_facebook"
                                target="_blank"
                                class="flex flex-row items-center"
                                ><img :src="facebookIconUrl" class="w-6 mr-2" alt="Facebook" />
                            </a>
                        </div>
                        <div v-if="$page.props.socialInfo.social_instagram">
                            <a
                                :href="$page.props.socialInfo.social_instagram"
                                target="_blank"
                                class="flex flex-row items-center"
                                ><img :src="instagramIconUrl" class="w-6 mr-2" alt="Instagram"
                            /></a>
                        </div>
                        <div v-if="$page.props.socialInfo.social_linkedin">
                            <a
                                :href="$page.props.socialInfo.social_linkedin"
                                target="_blank"
                                class="flex flex-row items-center"
                                ><img :src="linkedInIconUrl" class="w-6 mr-2" alt="LinkedIn"
                            /></a>
                        </div>
                        <div v-if="$page.props.socialInfo.social_twitter">
                            <a
                                :href="$page.props.socialInfo.social_twitter"
                                target="_blank"
                                class="flex flex-row items-center"
                                ><img :src="xTwitterIconUrl" class="w-6 mr-2" alt="X (Twitter)"
                            /></a>
                        </div>
                        <div v-if="$page.props.socialInfo.social_youtube">
                            <a
                                :href="$page.props.socialInfo.social_youtube"
                                target="_blank"
                                class="flex flex-row items-center"
                                ><img :src="youTubeIconUrl" class="w-6" alt="YouTube"
                            /></a>
                        </div>
                    </div>
                    <!-- Navigation menu -->
                    <!-- <nav class="flex flex-col">
                        <ul class="flex flex-wrap gap-6 text-sm uppercase tracking-wider text-white">
                            <li>
                                <InertiaLink :href="route('home')">{{ $t('Home') }}</InertiaLink>
                            </li>
                            <li>
                                <InertiaLink class="block w-max" :href="route('about')">{{
                                        $t('frontend.about_us', {app_name: $page.props.appName.toString()})
                                    }}</InertiaLink>
                            </li>
                            <li>
                                <InertiaLink class="block w-max" :href="route('why')">{{
                                        $t('frontend.why', {app_name: $page.props.appName.toString()})
                                    }}</InertiaLink>
                            </li>
                            <li>
                                <InertiaLink :href="route('technology')">{{
                                        $t('frontend.mcm_technology', {app_name: $page.props.appName.toString()})
                                    }}</InertiaLink>
                            </li>
                            <li>
                                <InertiaLink :href="route('treatments')">{{ $t('frontend.treatments') }}</InertiaLink>
                            </li>
                            <li>
                                <InertiaLink :href="route('events')">{{ $t('frontend.events') }}</InertiaLink>
                            </li>
                            <li>
                                <InertiaLink :href="route('contact.index')">{{ $t('frontend.contact.title') }}</InertiaLink>
                            </li>
                        </ul>
                    </nav> -->
                </div>
                <!-- Copyright + Powered by row -->
                <div class="flex flex-wrap justify-between gap-3">
                    <span class="text-sm text-white/80"
                        >&copy; {{ $page.props.legalInfo.legal_name }} 2024 - {{ $t('frontend.footer.present') }} -
                        {{ $t('frontend.footer.vat_number') }}: {{ $page.props.legalInfo.legal_vat_number }}</span
                    >
                    <span class="text-sm text-white/80"
                        >{{ $t('frontend.footer.designed_by') }}
                        <a href="https://generalservice.na.it" target="_blank" class="text-white">General Service</a>
                    </span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import {
    facebookIconUrl,
    instagramIconUrl,
    linkedInIconUrl,
    xTwitterIconUrl,
    youTubeIconUrl,
} from '@/images/components/social_media';
import LogoVertical from '@/images/logo_vertical.svg';

const hasCompanyInfo = computed(() => usePage().props.legalInfo && Object.keys(usePage().props.legalInfo)?.length);
const hasSocialInfo = computed(() => usePage().props.socialInfo && Object.keys(usePage().props.socialInfo)?.length);
</script>
