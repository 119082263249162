<template>
    <!-- NEW -->
    <div class="flex items-center">
        <!-- Logo -->
        <InertiaLink
            aria-current="page"
            :href="route('home')"
            class="router-link-active router-link-exact-active"
            title="">
            <JalorLogo class="h-auto w-48 md:cursor-pointer xl:w-52" />
        </InertiaLink>
        <!-- Separator -->
        <hr class="mx-4 h-auto self-stretch border-r border-secondary" />
        <!-- Locale Switcher -->
        <LocaleSwitcher />
    </div>
    <!-- Menu -->
    <div class="flex items-center">
        <!-- Desktop menu -->
        <nav class="flex items-center">
            <DesktopNavItems />
        </nav>
        <!-- Mobile menu -->
        <div>
            <div class="flex">
                <!-- Mobile menu icon -->
                <button
                    type="button"
                    aria-expanded="false"
                    class="h-full border-0 outline-0 dropdown"
                    @click="toggleMenu">
                    <div
                        class="flex flex-col duration-200 hover:opacity-80 xl:ml-5 xl:cursor-pointer 2xl:hidden"
                        role="img"
                        aria-label="menu">
                        <template v-if="show">
                            <!-- Close icon -->
                            <div
                                class="mb-2.5 h-0.5 w-6 origin-top-left rounded-lg bg-white transition -translate-y-[1.5px] rotate-45"></div>
                            <div
                                class="h-0.5 w-6 origin-bottom-left rounded-lg bg-white transition translate-y-[3px] -rotate-45"></div>
                        </template>
                        <template v-else>
                            <!-- Menu icon -->
                            <div
                                class="mb-2.5 h-0.5 w-6 origin-top-left rounded-lg bg-white transition translate-y-0 rotate-0"></div>
                            <div
                                class="h-0.5 w-6 origin-bottom-left rounded-lg bg-white transition translate-y-0 rotate-0"></div>
                        </template>
                    </div>
                </button>
                <!-- Mobile menu -->
                <nav
                    class="bg-black fixed top-0 -z-10 mx-auto flex h-screen w-full flex-col items-center justify-between overflow-scroll bg-j-dark-background-2 pb-4 pt-32 ease-in 2xl:hidden"
                    :class="animationClass"
                    tabindex="-1">
                    <MobileNavItems />
                    <!-- Mobile Footer -->
                    <div class="px-6">
                        <ul class="item-center mt-8 flex justify-center gap-8">
                            <li v-if="$page.props.socialInfo.social_facebook">
                                <a
                                    :href="$page.props.socialInfo.social_facebook"
                                    rel="noopener"
                                    target="_blank"
                                    title="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        role="img"
                                        aria-label="facebook"
                                        class="h-auto w-8 text-white">
                                        <path
                                            fill="currentColor"
                                            d="M15.53 9.18h-2.565V7.52c0-.623.419-.769.714-.769h1.81V4.01L12.995 4C10.229 4 9.6 6.045 9.6 7.353v1.828H8v2.825h1.6V20h3.365v-7.994h2.27l.294-2.825Z"></path>
                                    </svg>
                                </a>
                            </li>
                            <li v-if="$page.props.socialInfo.social_linkedin">
                                <a
                                    :href="$page.props.socialInfo.social_linkedin"
                                    rel="noopener"
                                    target="_blank"
                                    title="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        role="img"
                                        aria-label="linkedin"
                                        class="h-auto w-8 text-white">
                                        <path
                                            fill="currentColor"
                                            d="M7.861 7.73H4.443v12.205h3.418V7.73ZM15.978 7.73c-2.385 0-2.795.887-2.99 1.744V7.73H9.57v12.205h3.418V12.96c0-1.131.6-1.744 1.71-1.744 1.08 0 1.708.6 1.708 1.744v6.974h3.418v-6.103c0-3.487-.445-6.102-3.846-6.102ZM6.152 6.858c.944 0 1.71-.78 1.71-1.743 0-.963-.766-1.744-1.71-1.744s-1.709.78-1.709 1.744c0 .963.765 1.743 1.71 1.743Z"></path>
                                    </svg>
                                </a>
                            </li>
                            <li v-if="$page.props.socialInfo.social_instagram">
                                <a
                                    :href="$page.props.socialInfo.social_instagram"
                                    rel="noopener"
                                    target="_blank"
                                    title="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        role="img"
                                        aria-label="instagram"
                                        class="h-auto w-8 text-white">
                                        <path
                                            fill="currentColor"
                                            fill-rule="evenodd"
                                            d="M8.524 20A4.524 4.524 0 0 1 4 15.476V8.524A4.524 4.524 0 0 1 8.524 4h6.952A4.524 4.524 0 0 1 20 8.524v6.952A4.524 4.524 0 0 1 15.476 20H8.524Zm6.9-13.16a1.317 1.317 0 1 0 2.633 0 1.317 1.317 0 0 0-2.633 0Zm-7.617 5.159a4.19 4.19 0 1 0 8.381 0 4.19 4.19 0 0 0-8.38 0Z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                        <p
                            class="mx-auto mt-8 text-center text-xs font-medium uppercase leading-4 tracking-widest text-white">
                            &copy; {{ $page.props.legalInfo.legal_name }} 2024 - {{ $t('frontend.footer.present') }} -
                            {{ $t('frontend.footer.vat_number') }}: {{ $page.props.legalInfo.legal_vat_number }}
                        </p>
                        <p
                            class="mx-auto mt-3 break-words text-center text-xs font-medium uppercase leading-4 tracking-widest text-white opacity-70">
                            {{ $t('frontend.footer.designed_by') }}
                            <a href="https://generalservice.na.it" target="_blank" class="text-white"
                                >General Service</a
                            >
                        </p>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, ref} from 'vue';
import {Link as InertiaLink} from '@inertiajs/vue3';
import LocaleSwitcher from '@/Components/Theme/LocaleSwitcher.vue';
import DesktopNavItems from '@/Layouts/Partials/Frontend/DesktopNavItems.vue';
import MobileNavItems from '@/Layouts/Partials/Frontend/MobileNavItems.vue';
import {JalorLogo} from '@/images';

const show = ref(false);

const toggleMenu = () => {
    show.value = !show.value;
};

const animationClass = computed(() => ({
    'transition-all duration-200': true,
    'left-0': show.value,
    'left-full': !show.value,
}));
</script>
